import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { path as homePath } from "../../routes/HomeRoute";
import { APP_NAME, tabletQuery } from "../../config";
import {
	path as MajorListPath,
	useIsMatchMajorListRoute,
} from "../../routes/MajorListRoute";
import { useIsMobile } from "../../hooks/useIsMobile";

const Container = styled.header`
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 10;
	height: var(--header-height);
	background-color: var(--blue2);

	display: flex;
	justify-content: center;
	align-items: center;
`;

const InnerContainer = styled.div`
	width: var(--content-width);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;

	@media screen and ${tabletQuery} {
		padding: 0;
	}
`;

const Logo = styled.div`
	top: 0;
	left: 0;
	z-index: 11;

	display: flex;
	align-items: center;
	color: white;
	font-size: 30px;
	font-weight: bold;
	opacity: 1;

	cursor: pointer;
`;

function Header() {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const isMatchMajorListRoute = useIsMatchMajorListRoute();

	return (
		<Container>
			<InnerContainer>
				<Logo
					onClick={async () => {
						await navigate(homePath);
					}}
				>
					{APP_NAME}
				</Logo>
				{isMatchMajorListRoute && isMobile && (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						color="white"
						onClick={async () => {
							const searchParams = new URLSearchParams(window.location.search);
							if (searchParams.has("more")) {
								const moreValue = searchParams.get("more");
								if (moreValue === "true") {
									searchParams.delete("more");
								} else {
									searchParams.append("more", "true");
								}
							} else {
								searchParams.append("more", "true");
							}
							await navigate(`${MajorListPath}?${searchParams.toString()}`);
						}}
					>
						<MenuIcon />
					</Box>
				)}
			</InnerContainer>
		</Container>
	);
}

export default Header;
