import _ from "lodash";
import React from "react";
import querystring from "query-string";
import { useNavigate } from "react-router-dom";
import FilterPanel from "../FilterPanel";
import useSearchObject from "../../hooks/useSearchObject";
import Utils from "../../utils";
import useFiltersFromQuery from "../../hooks/useFiltersFromQuery";
import MajorCardList from "../MajorCardList";
import { ROWS_PER_PAGE } from "../../config";
import { path as majorListPath } from "../../routes/MajorListRoute";
import usePage from "../../hooks/usePage";

function MajorList() {
	const navigate = useNavigate();
	const searchObj = useSearchObject();
	const page = usePage();
	const more = Utils.toBooleanTrueString(searchObj.more);
	const filters = useFiltersFromQuery();

	return (
		<>
			<FilterPanel
				more={more}
				defaultState={filters}
				onClose={async () => {
					const obj = _.clone(filters);
					// @ts-ignore
					delete obj.more;
					await navigate(`${majorListPath}?${querystring.stringify(obj)}`);
				}}
				onMore={async () => {
					await navigate(
						`${majorListPath}?${querystring.stringify({
							...filters,
							more: true,
						})}`
					);
				}}
				onClear={() => {
					window.location.href = majorListPath;
				}}
				onOk={async (filters) => {
					await navigate(
						`${majorListPath}?${querystring.stringify({
							...Utils.formalizeFilters({ ...filters, more: false }),
						})}`
					);
				}}
			/>
			<MajorCardList
				limit={ROWS_PER_PAGE}
				page={page}
				onPageChange={async (page) => {
					await navigate(
						`${majorListPath}?${querystring.stringify({
							...filters,
							more,
							page,
						})}`
					);
				}}
				onSortChange={async (value) => {
					const queryObj = {
						...Utils.formalizeFilters({
							...filters,
							more,
						}),
					};
					if (value === "") {
						delete queryObj.sort_column;
						delete queryObj.sort_desc;

						await navigate(
							`${majorListPath}?${querystring.stringify(queryObj)}`
						);
					} else {
						queryObj.sort_desc = value === "qs_ranking_high_to_low";
						queryObj.sort_column = "qs_ranking";

						await navigate(
							`${majorListPath}?${querystring.stringify({ ...queryObj })}`
						);
					}
				}}
			/>
		</>
	);
}

export default MajorList;
