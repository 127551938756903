import _ from "lodash";
import React, { FC } from "react";
import styled from "styled-components";
import { useFindAll } from "../../queries/resource.query";
import useFiltersFromQuery from "../../hooks/useFiltersFromQuery";
import Pagination from "../Pagination";
import { MajorResultType } from "../../types/majorResultType";
import MajorCard from "../MajorCard";
import SortButton from "../SortButton";
import { ValueType } from "../SortButton/SortButton";
import { FilterType } from "../../types/filterType";
import { tabletQuery } from "../../config";

type Props = {
	limit: number;
	page: number;
	onPageChange: (page: number) => void;
	onSortChange: (value: ValueType) => void;
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	background-color: var(--grey2);
	min-height: calc(
		100vh - var(--header-height) - var(--filter-panel-close-height)
	);
`;

const InnerContainer = styled.div`
	width: var(--content-width);

	& > *:first-child {
		margin: 1rem 0 1rem 0;
	}
`;

const SortButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	@media screen and ${tabletQuery} {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
`;

function getSortValue(filters: Partial<FilterType>): ValueType {
	if (!_.has(filters, "sort_column")) {
		return "";
	}
	return filters.sort_desc
		? "qs_ranking_high_to_low"
		: "qs_ranking_low_to_high";
}

const MajorCardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 18px;

	@media screen and ${tabletQuery} {
		> * {
			width: 16rem;
		}
	}
`;

const MajorCardList: FC<Props> = ({
	page,
	limit,
	onPageChange,
	onSortChange,
}) => {
	const filters = useFiltersFromQuery();
	const majors = useFindAll<void, MajorResultType>(
		`major?limit=${limit}&page=${page}`,
		filters
	);

	if (majors.isLoading) {
		return <div>Loading</div>;
	} else if (majors.isError) {
		return <div>Sorry, we got some error</div>;
	} else {
		const { total_count, results } = majors.data!;
		if (total_count === 0) {
			return <div>There are no results</div>;
		}

		return (
			<Container>
				<InnerContainer>
					<SortButtonContainer>
						<SortButton value={getSortValue(filters)} onChange={onSortChange} />
					</SortButtonContainer>
					<MajorCardContainer>
						{results.map((major) => (
							<MajorCard key={major.id} {...major} />
						))}
					</MajorCardContainer>
					<div>
						<Pagination
							rowsPerPage={limit}
							page={page}
							totalCount={total_count}
							onChange={onPageChange}
						/>
					</div>
				</InnerContainer>
			</Container>
		);
	}
};

export default MajorCardList;
