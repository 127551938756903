import React, { FC } from "react";
import IconText from "./IconText";
import { GiGraduateCap } from "react-icons/gi";
import styled from "styled-components";

const NoteContainer = styled.div`
	margin-top: 1rem;
	margin-left: 45px;
	padding-left: 5px;
	padding-right: 5px;
`;

const OverallBox = styled.div`
	margin-top: 1rem;
	margin-left: 45px;
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	background-color: #e5eef8;
	width: 115px;
	height: 25px;
	color: #000000;
	font-size: 14px;
`;

const ScoreBoxContainer = styled.div`
	width: 20rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const ScoreBox = styled(OverallBox)`
	background-color: white;
`;

type ScoreContainerProps = {
	label: string;
	overall: number;
	listening: number;
	speaking: number;
	reading: number;
	writing: number;
	notes?: string;
};

const ScoreContainer: FC<ScoreContainerProps> = ({
	label,
	overall,
	listening,
	speaking,
	reading,
	writing,
	notes,
}) => {
	return (
		<div>
			<IconText
				icon={
					<GiGraduateCap style={{ fontSize: 25, color: "var(--purple)" }} />
				}
				text={
					<div>
						<div style={{ color: "#CC3E3E", fontWeight: "bold" }}>{label}</div>
					</div>
				}
			/>
			<OverallBox>
				<div>Total</div>
				<div>{overall}</div>
			</OverallBox>
			<ScoreBoxContainer>
				{listening !== 0 && (
					<ScoreBox>
						<div>Listening</div>
						<div>{listening}</div>
					</ScoreBox>
				)}
				{speaking !== 0 && (
					<ScoreBox>
						<div>Speaking</div>
						<div>{speaking}</div>
					</ScoreBox>
				)}
				{reading !== 0 && (
					<ScoreBox>
						<div>Reading</div>
						<div>{reading}</div>
					</ScoreBox>
				)}
				{writing !== 0 && (
					<ScoreBox>
						<div>Writing</div>
						<div>{writing}</div>
					</ScoreBox>
				)}
			</ScoreBoxContainer>
			{notes && (
				<NoteContainer>
					<div>
						Note<span style={{ marginLeft: 5, marginRight: 5 }}>:</span>
						{notes}
					</div>
				</NoteContainer>
			)}
		</div>
	);
};

export default ScoreContainer;
