import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/roboto";
import { QueryClient, QueryClientProvider } from "react-query";
import AppTheme from "../../themes/AppTheme";
import routes from "../../routes";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchOnMount: false,
		},
	},
});

const router = createBrowserRouter(routes);

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AppTheme>
				<RouterProvider router={router} />
			</AppTheme>
		</QueryClientProvider>
	);
}

export default App;
