import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { FilterType } from "../../types/filterType";
import OpenFilterPanel from "./OpenFilterPanel";
import onChange from "./onChange";
import styled from "styled-components";
import { useIsTablet } from "../../hooks/useIsTablet";
import { TabletCloseFilterPanel } from "./TabletCloseFilterPanel";

type Props = {
	more: boolean;
	defaultState: Partial<FilterType>;
	onMore: () => void;
	onClose: () => void;
	onOk: (filters: Partial<FilterType>) => void;
	onClear: () => void;
};

const Container = styled.div`
	box-shadow: 0 3px 6px #a0a0a066;
	background-color: var(--white);
`;

function FilterPanel({
	more,
	defaultState = {},
	onOk,
	onMore,
	onClose,
	onClear,
}: Props) {
	const [filters, setFilters] = useState(defaultState);
	const isTablet = useIsTablet();

	return (
		<Container>
			{isTablet && (
				<TabletCloseFilterPanel
					filters={filters}
					onChange={(field, value) => {
						onChange(setFilters, field, value);
					}}
					onMore={onMore}
					onClose={onClose}
					more={more}
				/>
			)}
			<Collapse in={more} unmountOnExit>
				<OpenFilterPanel
					filters={filters}
					onChange={(field, value) => {
						onChange(setFilters, field, value);
					}}
					onScoreFieldChange={(languages) => {
						setFilters((filters) => {
							return {
								...filters,
								...languages,
							};
						});
					}}
					onClose={onClose}
					onClear={onClear}
					onOk={() => onOk(filters)}
				/>
			</Collapse>
		</Container>
	);
}

export default FilterPanel;
