import React, { ReactNode } from "react";
import styled from "styled-components";
import { mobileQuery } from "../../config";

type Props = {
	children: ReactNode;
	titleColor: string;
	title: string;
	className?: string;
};

const contentWidth = "728px";

const Container = styled.div`
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	box-shadow: 0 3px 6px #a0a0a066;

	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and ${mobileQuery} {
		box-shadow: unset;
		padding: 1rem;
		margin: 0;
	}
`;

const Header = styled.header<{ backgroundColor: string }>`
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	width: 100%;
	height: 2rem;
	display: flex;
	justify-content: center;
	background-color: ${(props) => props.backgroundColor};

	& > div {
		width: ${contentWidth};
		display: flex;
		align-items: center;
		font-size: 1rem;
		font-weight: bold;
		color: white;
		text-transform: uppercase;
	}

	@media screen and ${mobileQuery} {
		padding-left: 1rem;
	}
`;

const Body = styled.main`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: var(--white);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	& > div {
		width: ${contentWidth};
		font-size: 14px;
		margin-top: 2rem;
		margin-bottom: 2rem;

		@media screen and ${mobileQuery} {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

	@media screen and ${mobileQuery} {
		padding-left: 1rem;
	}
`;

function Panel({ title, titleColor, className, children }: Props) {
	return (
		<Container className={className}>
			<Header backgroundColor={titleColor}>
				<div>{title}</div>
			</Header>
			<Body>{children}</Body>
		</Container>
	);
}

export default Panel;
