import { FeeUnitType, MajorOnePdfType } from "../../types/majorTypes";
import { getSection } from "./getSection";
import { CurrencyType } from "../../types/currencyType";

type TuitionFeeType = {
	fee_from: number;
	fee_to: number;
	fee_currency: CurrencyType;
	fee_unit: FeeUnitType;
};

const isOnePrice: (fee_from: number, fee_to: number) => boolean = (
	fee_from,
	fee_to
) => {
	return fee_from === fee_to;
};

const showTuitionFee: (obj: TuitionFeeType) => boolean = ({
	fee_from,
	fee_to,
	fee_currency,
	fee_unit,
}) => {
	return (
		fee_from !== 0 &&
		fee_to !== 0 &&
		fee_from <= fee_to &&
		fee_currency !== "" &&
		fee_unit !== ""
	);
};

type AppFeeType = {
	app_fee: number;
	app_currency: CurrencyType;
};

const showApplicationFee: (obj: AppFeeType) => boolean = ({
	app_fee,
	app_currency,
}) => {
	return app_fee !== 0 && app_currency !== "";
};

const toNumberString = (value: number) => {
	return value.toLocaleString();
};

export function getFeeContent(major: MajorOnePdfType): any[] {
	const isShowTuitionFee = showTuitionFee(major);
	const isShowApplicationFee = showApplicationFee(major);
	if (!isShowTuitionFee && !isShowApplicationFee) {
		return [];
	}

	const body = [["", ""]];
	let i = 0;
	if (isShowTuitionFee) {
		const price = isOnePrice(major.fee_from, major.fee_to)
			? toNumberString(major.fee_from)
			: `${toNumberString(major.fee_from)} - ${toNumberString(major.fee_to)}`;
		body[0][i++] = `Tuition fee ${price} / ${major.fee_unit.toLowerCase()} ${
			major.fee_currency
		}`;
	}

	if (isShowApplicationFee) {
		body[0][i++] = `Application fee ${toNumberString(major.app_fee)} ${
			major.app_currency
		}`;
	}

	if (i === 0) {
		return [];
	}

	return [
		getSection("FEES"),
		{
			layout: "noBorders",
			style: "content",
			table: {
				headerRows: 1,
				widths: ["40%", "40%"],
				body,
			},
		},
	];
}
