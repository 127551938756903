import axios, { AxiosInstance } from "axios";
import { API_URL } from "../config";

let ajax: AxiosInstance | null = null;

const useAjax = (): AxiosInstance => {
	if (!ajax) {
		ajax = axios.create({ baseURL: API_URL });
	}
	return ajax;
};

export default useAjax;
