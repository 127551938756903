import React, { CSSProperties, FC } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";

export type OptionType = {
	id: string;
	value: string;
	label: string;
	disabled?: boolean;
};

export type Props = {
	id: string;
	disabled: boolean;
	options: OptionType[];
	defaultLabel: string;
	value: string;
	error: string;
	onChange: (value: string) => void;
	className?: string;
	formStyle?: CSSProperties;
	style?: CSSProperties;
	variant?: "standard" | "outlined" | "filled";
};

const StyledSelect = styled(Select)`
	width: 100%;
	height: 45px;
	background: white;
	border-radius: 6px;
	color: ${(props) => (props.value === "" ? "#848484" : "black")};
	padding-left: 10px;

	select {
		width: 100%;

		:focus {
			background-color: white;
		}
	}
	svg {
		right: 10px;
	}
`;

const SelectField: FC<Props> = ({
	id,
	disabled,
	options,
	defaultLabel,
	error,
	value,
	onChange,
	className,
	formStyle,
	style,
	variant = "outlined",
}) => {
	const hasError = error !== "";

	return (
		<FormControl
			error={hasError}
			disabled={disabled}
			variant={variant}
			style={formStyle}
			className={className}
		>
			<StyledSelect
				style={style}
				autoWidth
				native
				value={value}
				onChange={(e) => onChange(e.target.value as string)}
			>
				<option value="">{defaultLabel}</option>
				{options.map(({ id: optionId, value, label, disabled = false }) => {
					return (
						<option
							key={`select-${id}_${optionId}`}
							disabled={disabled}
							value={value}
						>
							{label}
						</option>
					);
				})}
			</StyledSelect>
		</FormControl>
	);
};

export default SelectField;
