import React, { FC } from "react";
import styled from "styled-components";
import { FilterType } from "../../types/filterType";
import _ from "lodash";
import IOSSwitch from "../IOSSwitch";

type OnChangeType = (value: boolean) => void;
type Props = {
	label: string;
	filters: Partial<FilterType>;
	field: keyof FilterType;
	onChange: OnChangeType;
};

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 140px 1fr;
	font-size: 1rem;
`;

const BooleanField: FC<Props> = ({ label, onChange, field, filters }) => {
	return (
		<Container>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					marginRight: "1rem",
				}}
			>
				{label}
			</div>
			<IOSSwitch
				checked={(_.has(filters, [field]) ? filters[field] : false) as boolean}
				onChange={(event) => {
					onChange(event.target.checked);
				}}
			/>
		</Container>
	);
};

export default BooleanField;
