import React, { FC } from "react";
import Panel from "./Panel";
import RichText from "../RichText";

type Props = {
	features: string;
};

const FeaturesPanel: FC<Props> = ({ features }) => {
	return (
		<Panel title="features" titleColor="#6eadbc">
			<RichText>{features.replace(/\r?\n/g, "<br />")}</RichText>
		</Panel>
	);
};

export default FeaturesPanel;
