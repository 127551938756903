import styled from "styled-components";
import React, { CSSProperties, FC, ReactNode } from "react";

const Container = styled.button`
	border: solid 1px #aca9a9;
	border-radius: 6px;
	background-color: white;
	cursor: pointer;
`;

type Props = {
	children: ReactNode;
	onClick: () => void;
	style?: CSSProperties;
};

const OutlinedButton: FC<Props> = ({ style, onClick, children }) => {
	return (
		<Container style={style} onClick={onClick}>
			{children}
		</Container>
	);
};

export default OutlinedButton;
