import React, { ReactElement } from "react";
import "@fontsource/roboto";
import CssBaseline from "@mui/material/CssBaseline";
import "./AppTheme.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#5276A0",
		},
	},
});

const AppTheme = ({ children }: { children: ReactElement }) => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</>
	);
};

export default AppTheme;
