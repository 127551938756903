import React, { FC } from "react";
import styled from "styled-components";
import { MajorType } from "../../types/majorType";
import TopContainer from "./TopContainer";
import BottomContainer from "./BottomContainer";
import { prefixPath } from "../../routes/MajorOneRoute";
import { tabletQuery } from "../../config";
import { Link } from "react-router-dom";

const Container = styled(Link)`
	display: block;
	width: 100%;
	margin-left: 1rem;
	margin-right: 1rem;
	min-height: 20rem;
	background-color: white;
	box-shadow: 0 3px 6px #00000029;
	padding: 0.5rem;
	cursor: pointer;

	text-decoration: none;

	@media screen and ${tabletQuery} {
		margin-left: 0;
		margin-right: 0;
	}
`;

const Line = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
	height: 1px;
	width: 100%;
	background-color: #d5d5d5;
`;

const MajorCard: FC<MajorType> = ({
	id,
	logo_cut,
	name,
	university,
	country,
	city,
	qualification,
	pace,
	duration,
	ranking_qs,
	language_priority,
	ielts_overall,
	toefl_overall,
}) => {
	return (
		<Container to={`${prefixPath}/${id}`} title={`${university} - ${name}`}>
			<TopContainer
				name={name}
				logo_cut={logo_cut}
				qualification={qualification}
			/>
			<Line />
			<BottomContainer
				university={university}
				country={country}
				city={city}
				qualification={qualification}
				pace={pace}
				duration={duration}
				ranking_qs={ranking_qs}
				language_priority={language_priority}
				toefl_overall={toefl_overall}
				ielts_overall={ielts_overall}
			/>
		</Container>
	);
};

export default MajorCard;
