import { MajorOnePdfType } from "../../types/majorTypes";

export function getTOEFLContent(major: MajorOnePdfType): any {
	const scores = ["", "", "", "", "", "", "", ""];
	let i = 0;
	if (major.toefl_listening > 0) {
		scores[i++] = `Listening`;
		scores[i++] = `${major.toefl_listening}`;
	}
	if (major.toefl_speaking > 0) {
		scores[i++] = `Speaking`;
		scores[i++] = `${major.toefl_speaking}`;
	}
	if (major.toefl_listening > 0) {
		scores[i++] = `Listening`;
		scores[i++] = `${major.toefl_listening}`;
	}
	if (major.toefl_writing > 0) {
		scores[i++] = `Writing`;
		scores[i++] = `${major.toefl_writing}`;
	}

	return {
		layout: "noBorders",
		style: "content",
		table: {
			headerRows: 1,
			widths: [
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
				"12.5%",
			],
			body: [
				[
					{ text: "TOEFL", bold: true, margin: [0, 0, 0, 10] },
					`Total ${major.toefl_overall}`,
					"",
					"",
					"",
					"",
					"",
					"",
				],
				scores,
			],
			layout: {
				paddingLeft: function () {
					return 0;
				},
				paddingRight: function () {
					return 0;
				},
				paddingTop: function () {
					return 200;
				},
				paddingBottom: function () {
					return 0;
				},
			},
		},
	};
}
