import _ from "lodash";
import React from "react";
import styled from "styled-components";
import RemoteSelectField from "../RemoteSelectField";
import { FilterType } from "../../types/filterType";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

type OnChangeType = (field: keyof FilterType, value: any) => void;
type Props = {
	filters: Partial<FilterType>;
	onChange: OnChangeType;
	onMore: () => void;
	onClose: () => void;
	more: boolean;
};

const Container = styled.div`
	background-color: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--filter-panel-close-height);
`;

const InnerContainer = styled.div`
	position: relative;
	width: var(--content-width);
	display: flex;
	gap: 35px;
	> * {
		width: calc(33% - 30px);
	}
`;

const ArrowDown = styled(KeyboardArrowDown)`
	position: absolute;
	top: 25%;
	right: 0;
	cursor: pointer;
`;

const ArrowUp = styled(KeyboardArrowUp)`
	position: absolute;
	top: 25%;
	right: 0;
	cursor: pointer;
`;

export function TabletCloseFilterPanel({
	filters,
	onChange,
	onMore,
	onClose,
	more,
}: Props) {
	return (
		<Container>
			<InnerContainer>
				<RemoteSelectField
					id="country"
					url="major/country"
					optionValue="id"
					optionLabel="name"
					defaultLabel="Country"
					error=""
					disabled={false}
					value={_.has(filters, "country") ? filters.country! : ""}
					onChange={(value) => onChange("country", value)}
				/>
				<RemoteSelectField
					id="field_of_study"
					url="field_of_study"
					optionValue="id"
					defaultLabel="Field Of Study"
					error=""
					disabled={false}
					value={
						_.has(filters, "field_of_study") ? filters.field_of_study! : ""
					}
					onChange={(value) => onChange("field_of_study", value)}
				/>
				<RemoteSelectField
					id="qualification"
					url="qualification"
					optionValue="id"
					optionLabel="short_name"
					defaultLabel="Qualification"
					error=""
					disabled={false}
					value={_.has(filters, "qualification") ? filters.qualification! : ""}
					onChange={(value) => onChange("qualification", value)}
				/>
				{more ? (
					<ArrowUp onClick={() => onClose()} />
				) : (
					<ArrowDown onClick={() => onMore()} />
				)}
			</InnerContainer>
		</Container>
	);
}
