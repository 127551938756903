import React, { CSSProperties, FC } from "react";
import OutlinedButton from "./OutlinedButton";

type Props = {
	onClick: () => void;
	style?: CSSProperties;
};

const DetailButton: FC<Props> = ({ onClick, style }) => {
	return (
		<OutlinedButton style={style} onClick={onClick}>
			Detail
		</OutlinedButton>
	);
};

export default DetailButton;
