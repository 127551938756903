import React from "react";
import MainLayout from "../components/MainLayout";
import MajorList from "../components/MajorList";
import Header from "../components/MainLayout/Header";
import { useMatch } from "react-router-dom";

export const path = "/major";

export function useIsMatchMajorListRoute(): boolean {
	return !!useMatch(path);
}

function MajorListRoute() {
	return (
		<MainLayout>
			<Header />
			<MajorList />
		</MainLayout>
	);
}

export default MajorListRoute;
