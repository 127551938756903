import React from "react";
import styled from "styled-components";
import UniversityLogo from "../UniversityLogo";
import QualificationTag from "./QualificationTag";
import WhiteTag from "./WhiteTag";
import { mobileQuery } from "../../config";
import { MajorOnePdfButton } from "./MajorOnePdfButton";

type Props = {
	website: string;
	logo_cut: string;
	name: string;
	university: string;
	qualification: string;
	type_of_school: string;
	level: string;
};

const leftGap = "20px";

const Container = styled.div`
	min-height: 222px;
	background-color: var(--white);
	border-bottom: solid 5px #ed8787;
	border-radius: 6px;
	box-shadow: 0 3px 6px #a0a0a066;
	padding: 30px 43px 30px 43px;

	display: grid;
	grid-template-columns: 250px 1fr;
	grid-template-areas:
		"logo a a"
		"logo b b"
		".    c c";

	> *:nth-child(1) {
		grid-area: logo;
	}

	> *:nth-child(2) {
		grid-area: a;
		padding-left: ${leftGap};
	}

	> *:nth-child(3) {
		grid-area: b;
		padding-top: 20px;
		padding-left: ${leftGap};
	}

	> *:nth-child(4) {
		grid-area: c;

		padding-top: 20px;
		padding-left: ${leftGap};
		display: flex;
		align-items: center;

		*:not(:first-child) {
			margin-left: 1rem;
		}
	}

	@media screen and ${mobileQuery} {
		margin-left: 1rem;
		margin-right: 1rem;
		grid-template-columns: unset;
		grid-template-rows: repeat(1fr, 4);
		grid-template-areas:
			"logo"
			"a"
			"b"
			"c";

		> *:nth-child(2) {
			padding: 1rem 0 0 0;
		}

		> *:nth-child(3) {
			padding: 1rem 0 0 0;
		}

		> *:nth-child(4) {
			padding: 1rem 0 0 0;
		}
	}
`;

const Name = styled.a`
	color: #244872;
	font-size: 30px;
	font-weight: bold;
	text-decoration: none;
`;

const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;

	@media screen and ${mobileQuery} {
		justify-content: center;
	}
`;

function TitlePanel({
	website,
	logo_cut,
	name,
	university,
	qualification,
	type_of_school,
	level,
}: Props) {
	return (
		<Container>
			<LogoContainer>
				<UniversityLogo variant="big" name={university} src={logo_cut} />
			</LogoContainer>
			<Name href={website} rel="noopener noreferrer" target="_blank">
				{name}
			</Name>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ color: "#000000", fontSize: 25, fontWeight: "bold" }}>
					{university}
				</div>
				<QualificationTag style={{ marginLeft: 20 }}>
					{qualification}
				</QualificationTag>
			</div>
			<div>
				<WhiteTag>{type_of_school}</WhiteTag>
				<WhiteTag>{level}</WhiteTag>
				<MajorOnePdfButton />
			</div>
		</Container>
	);
}

export default TitlePanel;
