import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledButton = styled.button<{ backgroundColor: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--white);
	background-color: ${(props) => props.backgroundColor};
	border-radius: 6px;

	font-size: 1rem;
	width: 10rem;
	height: 2rem;

	appearance: none;
	border: none;

	&:hover {
		cursor: pointer;
	}
`;
type Props = {
	id: string;
	onClick: () => void;
	backgroundColor?: string;
	children: ReactNode;
};

const Button: FC<Props> = ({ id, children, backgroundColor, onClick }) => {
	return (
		<StyledButton
			id={id}
			onClick={() => onClick()}
			backgroundColor={backgroundColor ? backgroundColor : "var(--blue2)"}
		>
			{children}
		</StyledButton>
	);
};

export default Button;
