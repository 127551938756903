import React, { FC } from "react";
import styled from "styled-components";
import IconText from "./IconText";
import Icon from "../Icon";
import placeSvg from "../resources/place.svg";
import clockSvg from "../resources/clock.svg";
import qsRankingSvg from "../resources/qs_ranking.svg";
import hatSvg from "../resources/hat.svg";
import { LanguagePriorityType } from "../../types/majorType";

type Props = {
	university: string;
	country: string;
	city: string;
	qualification: string;
	pace: string;
	duration: string;
	ranking_qs: number;
	language_priority: LanguagePriorityType;
	ielts_overall: number;
	toefl_overall: number;
};

const Container = styled.div`
	margin-top: 0.5rem;
	margin-left: 12px;
`;

const UniversityName = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: var(--grey);

	width: 228px;
	text-overflow: ellipsis;
	white-space: normal;
	overflow: hidden;
`;

const FieldsContainer = styled.div`
	> * {
		margin-top: 20px;
	}
`;

const BottomContainer: FC<Props> = ({
	university,
	city,
	country,
	pace,
	duration,
	ranking_qs,
	language_priority,
	ielts_overall,
	toefl_overall,
}) => {
	const place =
		city === "" && country === "" ? "No data" : `${city}, ${country}`;
	const clock = `${pace}, ${duration}`;
	const qs = `QS ${ranking_qs}`;
	const score =
		language_priority === "IELTS"
			? `IELTS ${ielts_overall}`
			: `TOEFL ${toefl_overall}`;

	return (
		<Container>
			<UniversityName>{university}</UniversityName>
			<FieldsContainer>
				<IconText icon={<Icon src={placeSvg} alt={place} />} text={place} />
				<IconText icon={<Icon src={clockSvg} alt={clock} />} text={clock} />
				<IconText icon={<Icon src={qsRankingSvg} alt={qs} />} text={qs} />
				<IconText icon={<Icon src={hatSvg} alt={score} />} text={score} />
			</FieldsContainer>
		</Container>
	);
};

export default BottomContainer;
