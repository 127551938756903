import React from "react";
import RemoteSelectField from "../RemoteSelectField";
import styled from "styled-components";
import { tabletQuery } from "../../config";

type Props = {
	country: string;
	setCountry: (s: string) => void;

	fieldOfStudy: string;
	setFieldOfStudy: (s: string) => void;

	qualification: string;
	setQualification: (s: string) => void;
};

const Container = styled.div`
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-gap: 2.5rem;

	@media screen and ${tabletQuery} {
		grid-template-rows: unset;
		grid-template-columns: repeat(3, 1fr);

		& > * > * {
			width: 325px;
		}
	}
`;

function SelectRow({
	country,
	setCountry,
	fieldOfStudy,
	setFieldOfStudy,
	qualification,
	setQualification,
}: Props) {
	return (
		<Container>
			<RemoteSelectField
				id="country"
				url="major/country"
				optionValue="id"
				optionLabel="name"
				defaultLabel="Country"
				error=""
				disabled={false}
				variant="standard"
				value={country}
				onChange={setCountry}
			/>
			<RemoteSelectField
				id="field_of_study"
				url="field_of_study"
				optionValue="id"
				defaultLabel="Field of Study"
				error=""
				disabled={false}
				variant="standard"
				value={fieldOfStudy}
				onChange={setFieldOfStudy}
			/>
			<RemoteSelectField
				id="qualification"
				url="qualification"
				optionValue="id"
				optionLabel="short_name"
				defaultLabel="Qualification"
				error=""
				disabled={false}
				variant="standard"
				value={qualification}
				onChange={setQualification}
			/>
		</Container>
	);
}

export default SelectRow;
