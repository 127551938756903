import React, { CSSProperties, FC } from "react";
import OutlinedButton from "./OutlinedButton";

type Props = {
	onClick: () => void;
	style?: CSSProperties;
};

const ClearButton: FC<Props> = ({ onClick, style }) => {
	return (
		<OutlinedButton style={style} onClick={onClick}>
			Clear
		</OutlinedButton>
	);
};

export default ClearButton;
