import { FaRegFilePdf } from "react-icons/fa";
import React from "react";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useOnPdfClick } from "../../hooks/pdf/useOnPdfClick";

export function MajorOnePdfButton() {
	const { id } = useParams();
	const onPdfClick = useOnPdfClick();
	if (!id) return null;

	return (
		<Tooltip title="下載 PDF" onClick={() => onPdfClick(id)}>
			<IconButton>
				<FaRegFilePdf />
			</IconButton>
		</Tooltip>
	);
}
