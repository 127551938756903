const queryKeys = {
	AUTH_LOGIN: "AUTH_LOGIN",
	RESOURCE_ADD: "RESOURCE_ADD",
	RESOURCE_FIND_ALL: "RESOURCE_FIND_ALL",
	RESOURCE_FIND_ONE: "RESOURCE_FIND_ONE",
	RESOURCE_UPDATE_COLUMN: "RESOURCE_UPDATE_COLUMN",
	RESOURCE_UPDATE_RECORD: "RESOURCE_UPDATE_RECORD",
	RESOURCE_REMOVE: "RESOURCE_REMOVE",
	ME: "ME",
};

export default queryKeys;
