import _ from "lodash";
import React, { FC } from "react";
import { FilterType } from "../../types/filterType";
import RangeBar from "../RangeBar";
import RowContainer from "./RowContainer";
import ClearButton from "./ClearButton";

type OnChangeType = (field: keyof FilterType, value: any) => void;
type Props = {
	filters: Partial<FilterType>;
	onChange: OnChangeType;
};

const QsRankingRow: FC<Props> = ({ filters, onChange }) => {
	return (
		<RowContainer
			field="QS Ranking"
			right={
				<ClearButton
					onClick={() => {
						onChange("qs_ranking", [0, 0]);
					}}
				/>
			}
		>
			<div style={{ width: "100%", paddingLeft: "1rem", paddingRight: "1rem" }}>
				<RangeBar
					id="qs_ranking_bar"
					min={0}
					max={1000}
					step={10}
					defaultValue={
						_.has(filters, "qs_ranking") ? filters.qs_ranking! : [0, 0]
					}
					onChange={(value) => {
						onChange("qs_ranking", value);
					}}
				/>
			</div>
		</RowContainer>
	);
};

export default QsRankingRow;
