import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Field from "./Field";

type Props = {
	field: string;
	right: ReactNode;
	children: ReactNode;
};

const Container = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;

	& > *:nth-child(2) {
		width: 100%;
	}
`;

const RightContainer = styled.div`
	margin-left: 35px;
`;

const RowContainer: FC<Props> = ({ field, children, right }) => {
	return (
		<Container>
			<Field>{field}</Field>
			{children}
			<RightContainer>{right}</RightContainer>
		</Container>
	);
};

export default RowContainer;
