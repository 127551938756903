import React, { CSSProperties, FC } from "react";
import SelectField from "../SelectField";
import { useFindAll } from "../../queries/resource.query";
import { OptionType } from "../SelectField/SelectField";
import { ResourceType } from "../../types/categoryTypes";

type Props = {
	id: string;
	url: string;
	defaultLabel: string;
	error: string;
	value: string;
	disabled: boolean;
	onChange: (value: string) => void;
	optionLabel?: string;
	optionValue?: string;
	style?: CSSProperties;
	variant?: "standard" | "outlined" | "filled";
};

const RemoteSelectField: FC<Props> = ({
	id,
	url,
	defaultLabel,
	error,
	value,
	disabled,
	onChange,
	optionLabel = "name",
	optionValue = "id",
	style,
	variant = "outlined",
}) => {
	const findAll = useFindAll<() => void, ResourceType[]>(url);
	let options: OptionType[] = [];
	if (!findAll.isLoading && findAll.data) {
		options = findAll.data.map((e) => {
			const label = e[optionLabel];
			const value = e[optionValue];

			return {
				id: e.id ? e.id.toString() : value,
				label,
				value,
			};
		}) as OptionType[];
	}

	return (
		<SelectField
			id={id}
			disabled={findAll.isLoading || disabled}
			options={options}
			defaultLabel={defaultLabel}
			error={error}
			value={value}
			onChange={onChange}
			variant={variant}
			style={style}
		/>
	);
};

export default RemoteSelectField;
