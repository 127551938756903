import React from "react";
import MainLayout from "../components/MainLayout";
import Home from "../components/Home";

export const path = "/";

function HomeRoute() {
	return (
		<MainLayout>
			<Home />
		</MainLayout>
	);
}

export default HomeRoute;
