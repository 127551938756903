import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
// @ts-ignore
import createMuiStrictModeTheme from "@mui/material/styles/createMuiStrictModeTheme";

type Props = {
	id: string;
	min: number;
	max: number;
	step: number;
	defaultValue: [number, number];
	onChange: (value: [number, number]) => void;
	style?: CSSProperties;
};

const RangeBar: FC<Props> = ({
	id,
	min,
	max,
	step,
	defaultValue,
	onChange,
	style,
}) => {
	const isChangingRef = useRef() as any;
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (value !== defaultValue && !isChangingRef?.current) {
			setValue(defaultValue);
		}
	}, [value, defaultValue]);

	return (
		<ThemeProvider
			theme={createMuiStrictModeTheme({
				palette: {
					primary: {
						main: "#4186D6",
					},
				},
			})}
		>
			<Slider
				id={id}
				size="small"
				min={min}
				max={max}
				step={step}
				value={value}
				style={style}
				onChange={(ignored, value) => {
					isChangingRef.current = true;
					setValue(value as [number, number]);
				}}
				onChangeCommitted={(ignored, value) => {
					isChangingRef.current = false;
					onChange(value as [number, number]);
				}}
				getAriaValueText={() => {
					return "";
				}}
				aria-labelledby="range-slider"
				valueLabelDisplay="auto"
			/>
		</ThemeProvider>
	);
};

export default RangeBar;
