import _ from "lodash";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { FilterType } from "../../types/filterType";
import ScoreFields from "./ScoreFields/ScoreFields";
import Button from "../Button";
import RemoteSelectField from "../RemoteSelectField";
import QsRankingRow from "./QsRankingRow";
import BooleanField from "./BooleanField";
import { mobileQuery, tabletQuery } from "../../config";
import { useIsMobile } from "../../hooks/useIsMobile";

type OnChangeType = (field: keyof FilterType, value: any) => void;
type Props = {
	filters: Partial<FilterType>;
	onChange: OnChangeType;
	onScoreFieldChange: (value: Partial<FilterType>) => void;
	onClose: () => void;
	onOk: (filters: Partial<FilterType>) => void;
	onClear: () => void;
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const InnerContainer = styled.div`
	position: relative;
	width: var(--content-width);

	@media screen and ${mobileQuery} {
		width: 100%;
	}
`;

const SelectRow = styled.div`
	display: grid;

	> :nth-child(1) {
		grid-area: city;
	}

	> :nth-child(2) {
		grid-area: university;
	}

	> :nth-child(3) {
		grid-area: pace;
	}

	> :nth-child(4) {
		grid-area: duration;
	}

	> :nth-child(5) {
		grid-area: qs-ranking;
	}

	> :nth-child(6) {
		grid-area: toefl;
	}

	> :nth-child(7) {
		grid-area: ielts;
	}

	> :nth-child(8) {
		grid-area: application-fee;
	}

	> :nth-child(9) {
		grid-area: work-placement;
	}

	> :nth-child(10) {
		grid-area: any-background;
	}

	@media screen and ${mobileQuery} {
		grid-gap: 1rem;
		grid-template-areas:
			"city"
			"university"
			"pace"
			"duration"
			"qs-ranking"
			"toefl"
			"ielts"
			"application-fee"
			"work-placement"
			"any-background";
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 16px;
	}

	@media screen and ${tabletQuery} {
		grid-template-areas:
			"city            university      pace"
			"duration        duration        duration"
			"qs-ranking      qs-ranking      qs-ranking"
			"toefl           toefl           toefl"
			"ielts           ielts           ielts"
			"application-fee application-fee application-fee"
			"work-placement  work-placement  work-placement"
			"any-background  any-background  any-background";

		> :nth-child(4) {
			margin-top: 1.5rem;
		}

		> :nth-child(5),
		> :nth-child(6),
		> :nth-child(7) {
			width: 70% !important;
		}

		> :nth-child(8) {
			justify-content: flex-start;
		}

		> :nth-child(9) {
			justify-content: flex-start;
		}

		> :nth-child(10) {
			justify-content: flex-start;
		}

		& > * {
			width: 330px;
			display: flex;
			justify-content: center;
			align-content: center;
			padding-top: 1rem;
		}
	}
`;

const BlueButton: FC<{
	id: string;
	onClick: () => void;
	children: ReactNode;
}> = ({ id, onClick, children }) => {
	return (
		<Button
			backgroundColor="var(--blue4)"
			onClick={() => {
				onClick();
			}}
			id={id}
		>
			{children}
		</Button>
	);
};

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-top: 1rem;

	> *:not(:first-child) {
		margin-left: 1rem;
	}
`;

function OpenFilterPanel({
	onChange,
	onScoreFieldChange,
	filters,
	onClose,
	onClear,
	onOk,
}: Props) {
	const isMobile = useIsMobile();
	return (
		<Container>
			<InnerContainer>
				<SelectRow>
					{isMobile && (
						<>
							<RemoteSelectField
								id="country"
								url="major/country"
								optionValue="id"
								optionLabel="name"
								defaultLabel="Country"
								error=""
								disabled={false}
								value={_.has(filters, "country") ? filters.country! : ""}
								onChange={(value) => onChange("country", value)}
							/>
							<RemoteSelectField
								id="field_of_study"
								url="field_of_study"
								optionValue="id"
								defaultLabel="Field Of Study"
								error=""
								disabled={false}
								value={
									_.has(filters, "field_of_study")
										? filters.field_of_study!
										: ""
								}
								onChange={(value) => onChange("field_of_study", value)}
							/>
							<RemoteSelectField
								id="qualification"
								url="qualification"
								optionValue="id"
								optionLabel="short_name"
								defaultLabel="Qualification"
								error=""
								disabled={false}
								value={
									_.has(filters, "qualification") ? filters.qualification! : ""
								}
								onChange={(value) => onChange("qualification", value)}
							/>
						</>
					)}
					<RemoteSelectField
						id="city-list"
						url={`major/city?country=${filters.country}`}
						defaultLabel="City"
						error=""
						disabled={filters.country === ""}
						value={_.has(filters, "city") ? filters.city! : ""}
						onChange={(value) => {
							onChange("city", value);
						}}
					/>
					<RemoteSelectField
						id="university"
						url="university"
						defaultLabel="University"
						error=""
						disabled={filters.university === ""}
						value={_.has(filters, "university") ? filters.university! : ""}
						onChange={(value) => onChange("university", value)}
					/>
					<RemoteSelectField
						id="pace"
						url="pace"
						defaultLabel="Pace"
						error=""
						disabled={filters.pace === ""}
						value={_.has(filters, "pace") ? filters.pace! : ""}
						onChange={(value) => onChange("pace", value)}
					/>
					<RemoteSelectField
						id="duration"
						url="duration"
						defaultLabel="Duration"
						error=""
						disabled={filters.duration === ""}
						value={_.has(filters, "duration") ? filters.duration! : ""}
						onChange={(value) => onChange("duration", value)}
					/>
					<QsRankingRow
						filters={filters}
						onChange={(field, values) => {
							onChange(field, values);
						}}
					/>
					<ScoreFields
						id="toefl"
						label="TOEFL"
						step={1}
						totalMin={0}
						totalMax={120}
						eachMin={0}
						eachMax={30}
						defaultValue={{
							overall: _.has(filters, ["toefl_overall"])
								? filters.toefl_overall!
								: [0, 0],
							listening: _.has(filters, ["toefl_listening"])
								? filters.toefl_listening!
								: [0, 0],
							speaking: _.has(filters, ["toefl_speaking"])
								? filters.toefl_speaking!
								: [0, 0],
							reading: _.has(filters, ["toefl_reading"])
								? filters.toefl_reading!
								: [0, 0],
							writing: _.has(filters, ["toefl_writing"])
								? filters.toefl_writing!
								: [0, 0],
						}}
						onChange={(value) => {
							onScoreFieldChange({
								toefl_overall: value.overall,
								toefl_listening: value.listening,
								toefl_speaking: value.speaking,
								toefl_reading: value.reading,
								toefl_writing: value.writing,
							});
						}}
					/>

					<ScoreFields
						id="ielts"
						label="IELTS"
						step={0.5}
						totalMin={0}
						totalMax={9}
						eachMin={0}
						eachMax={9}
						defaultValue={{
							overall: _.has(filters, ["ielts_overall"])
								? filters.ielts_overall!
								: [0, 0],
							listening: _.has(filters, ["ielts_listening"])
								? filters.ielts_listening!
								: [0, 0],
							speaking: _.has(filters, ["ielts_speaking"])
								? filters.ielts_speaking!
								: [0, 0],
							reading: _.has(filters, ["ielts_reading"])
								? filters.ielts_reading!
								: [0, 0],
							writing: _.has(filters, ["ielts_writing"])
								? filters.ielts_writing!
								: [0, 0],
						}}
						onChange={(value) => {
							onScoreFieldChange({
								ielts_overall: value.overall,
								ielts_listening: value.listening,
								ielts_speaking: value.speaking,
								ielts_reading: value.reading,
								ielts_writing: value.writing,
							});
						}}
					/>
					<BooleanField
						label="Application Fee"
						field="application_fee"
						filters={filters}
						onChange={(value) => {
							onChange("application_fee", value);
						}}
					/>

					<BooleanField
						label="Work placement"
						field="work_placement"
						filters={filters}
						onChange={(value) => {
							onChange("work_placement", value);
						}}
					/>
					<BooleanField
						label="Any background"
						field="any_background"
						filters={filters}
						onChange={(value) => {
							onChange("any_background", value);
						}}
					/>
				</SelectRow>
				<ButtonsContainer>
					<BlueButton
						id="ok_btn"
						onClick={() => {
							onOk(filters);
						}}
					>
						Ok
					</BlueButton>
					<BlueButton id="clear_btn" onClick={onClear}>
						Clear
					</BlueButton>
					<BlueButton id="close_btn" onClick={onClose}>
						Close
					</BlueButton>
				</ButtonsContainer>
			</InnerContainer>
		</Container>
	);
}

export default OpenFilterPanel;
