import React, { FC } from "react";
import styled from "styled-components";
import { AiFillFlag } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import mapSvg from "../resources/map.svg";
import Panel from "./Panel";
import IconText from "./IconText";
import clockSvg from "../resources/purpleClock.svg";
import A from "../elements/A";
import _ from "lodash";

const Container = styled.div`
	& > *:not(:first-child) {
		margin-top: 1rem;
	}
`;

type Props = {
	country: string;
	city: string;
	address: string;
	pace: string;
	duration: string;
	work_placement: boolean;
	has_post_study_work_visa: boolean;
};

const BoldText = styled.div`
	font-size: 1rem;
	color: #244872;
	font-weight: bold;
`;

const MarkIcon = styled(IoIosCheckmarkCircle)`
	font-size: 20px;
	fill: #63ba2c;
`;

const LocationPanel: FC<Props> = ({
	country,
	city,
	address,
	pace,
	duration,
	work_placement,
	has_post_study_work_visa,
}) => {
	const clock = `${pace} - ${duration}`;

	return (
		<Panel title="Location & INFORMATION" titleColor="#CE7899">
			<Container>
				{!_.isEmpty(country) && (
					<IconText
						icon={
							<AiFillFlag style={{ fontSize: 16, color: "var(--purple)" }} />
						}
						text={<BoldText>{country}</BoldText>}
					/>
				)}
				{!_.isEmpty(city) && (
					<IconText
						icon={
							<FaMapMarkerAlt
								style={{ fontSize: 16, color: "var(--purple)" }}
							/>
						}
						text={<BoldText>{city}</BoldText>}
					/>
				)}
				{!_.isEmpty(address) && (
					<IconText
						icon={<img src={mapSvg} alt={address} />}
						text={
							<A
								title={address}
								target="_blank"
								href={`https://www.google.com.tw/maps/place/${address}`}
							>
								{address}
							</A>
						}
					/>
				)}
				<IconText icon={<img src={clockSvg} alt={clock} />} text={clock} />
				{work_placement && (
					<IconText icon={<MarkIcon />} text="Work placement" />
				)}
				{has_post_study_work_visa && (
					<IconText icon={<MarkIcon />} text="Post-Study work visa" />
				)}
			</Container>
		</Panel>
	);
};

export default LocationPanel;
