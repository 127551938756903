import React, { FC } from "react";
import MainLayout from "../components/MainLayout";
import MajorOne from "../components/MajorOne";
import Header from "../components/MainLayout/Header";

export const prefixPath = "/major";
export const path = `${prefixPath}/:id`;

const MajorOneRoute: FC = () => {
	return (
		<MainLayout>
			<Header />
			<MajorOne />
		</MainLayout>
	);
};

export default MajorOneRoute;
