import styled from "styled-components";

const WhiteTag = styled.div`
	width: fit-content;
	color: var(--grey);
	border-radius: 6px;
	border: solid 1px var(--grey);
	padding-left: 5px;
	padding-right: 5px;
	font-size: 14px;
`;

export default WhiteTag;
