import { useQuery } from "react-query";
import queryKeys from "./queryKeys";
import useAjax from "../hooks/useAjax";
import { ErrorType } from "../types/statusErrorTypes";

const url = "/";

export type RecordType = unknown & {
	id: string;
};

export const getFindAllKey = (resource: string, params?: any) => {
	return [queryKeys.RESOURCE_FIND_ALL, resource, params];
};

export const getFindOneKey = (resource: string, id: string) => {
	return [queryKeys.RESOURCE_FIND_ONE, resource, id];
};

export function useFindAll<TQueryFnData, TData>(
	resource: string,
	params?: any
) {
	const ajax = useAjax();
	return useQuery<TQueryFnData, ErrorType, TData>(
		getFindAllKey(resource, params),
		() => ajax.get(`${url}${resource}`, { params }).then((e) => e.data)
	);
}

export function useFindOne<TData = RecordType>(resource: string, id: string) {
	const enabled = id !== "";
	const ajax = useAjax();

	return useQuery<void, ErrorType, TData>(
		getFindOneKey(resource, id),
		() => ajax.get(`${url}${resource}/${id}`).then((e) => e.data),
		{ enabled }
	);
}
