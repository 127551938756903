import React, { FC } from "react";
import styled from "styled-components";
import { RiGlobalLine } from "react-icons/ri";
import Panel from "./Panel";
import ScoreContainer from "./ScoreContainer";
import IconText from "./IconText";
import A from "../elements/A";

type Props = {
	ielts_overall: number;
	ielts_listening: number;
	ielts_speaking: number;
	ielts_reading: number;
	ielts_writing: number;
	ielts_notes: string;
	toefl_overall: number;
	toefl_listening: number;
	toefl_speaking: number;
	toefl_reading: number;
	toefl_writing: number;
	language_website: string;
};

const Container = styled.div`
	& > *:not(:first-child) {
		margin-top: 1rem;
	}
`;

const LanguageRequirementPanel: FC<Props> = ({
	ielts_overall,
	ielts_listening,
	ielts_speaking,
	ielts_reading,
	ielts_writing,
	ielts_notes,
	toefl_overall,
	toefl_listening,
	toefl_speaking,
	toefl_reading,
	toefl_writing,
	language_website,
}) => {
	return (
		<Panel title="Language Requirement" titleColor="#5384BE">
			<Container>
				<ScoreContainer
					label="IELTS"
					overall={ielts_overall}
					listening={ielts_listening}
					speaking={ielts_speaking}
					reading={ielts_reading}
					writing={ielts_writing}
					notes={ielts_notes}
				/>
				<ScoreContainer
					label="TOEFL"
					overall={toefl_overall}
					listening={toefl_listening}
					speaking={toefl_speaking}
					reading={toefl_reading}
					writing={toefl_writing}
				/>
				{language_website && (
					<IconText
						icon={
							<RiGlobalLine style={{ fontSize: 25, color: "var(--purple)" }} />
						}
						text={
							<div>
								Pre-sessional English course:{" "}
								<A target="_blank" href={language_website}>
									{language_website}
								</A>
							</div>
						}
					/>
				)}
			</Container>
		</Panel>
	);
};

export default LanguageRequirementPanel;
