import _ from "lodash";
import { FilterType } from "../types/filterType";
import useSearchObject from "./useSearchObject";
import Utils from "../utils";
import useURLSearchParams from "./useURLSearchParams";

type PartialFilterType = Partial<FilterType>;
type UseFiltersFromQueryType = () => Partial<FilterType>;
type SearchObjectType = { [k: string]: any };
type FieldType = keyof FilterType;

const handleString = (
	searchObject: SearchObjectType,
	filter: PartialFilterType,
	field: FieldType
): PartialFilterType => {
	if (_.has(searchObject, field) && searchObject[field] !== "") {
		filter[field] = searchObject[field];
	}
	return filter;
};

const handleNumber = (
	searchObject: SearchObjectType,
	filter: PartialFilterType,
	field: FieldType
): PartialFilterType => {
	if (
		_.has(searchObject, field) &&
		Utils.isNumericString(searchObject[field])
	) {
		const value = parseInt(searchObject[field]);
		if (value !== 0) {
			filter[field] = value as any;
		}
	}
	return filter;
};

const handleBoolean = (
	searchObject: SearchObjectType,
	filter: PartialFilterType,
	field: FieldType
): PartialFilterType => {
	if (
		_.has(searchObject, field) &&
		Utils.toBooleanString(searchObject[field])
	) {
		const value: boolean = searchObject[field] === "true";
		if (value) {
			filter[field] = value as any;
		}
	}
	return filter;
};

const handleDimension = (
	urlSearchParams: URLSearchParams,
	filter: PartialFilterType,
	field: FieldType
): PartialFilterType => {
	if (urlSearchParams.has(field)) {
		const values = urlSearchParams.getAll(field).map((e) => parseFloat(e)) as [
			number,
			number
		];

		if (values.length === 2 && values.toString() !== "0,0") {
			filter[field] = values as any;
		}
	}
	return filter;
};

const useFiltersFromQuery: UseFiltersFromQueryType = () => {
	const urlSearchParams = useURLSearchParams();
	const searchObject = useSearchObject();
	let filter: PartialFilterType = {};

	filter = handleString(searchObject, filter, "country");
	filter = handleString(searchObject, filter, "field_of_study");
	filter = handleString(searchObject, filter, "qualification");
	filter = handleNumber(searchObject, filter, "city");
	filter = handleString(searchObject, filter, "university");
	filter = handleString(searchObject, filter, "pace");
	filter = handleString(searchObject, filter, "duration");
	filter = handleString(searchObject, filter, "sort_column");

	filter = handleBoolean(searchObject, filter, "application_fee");
	filter = handleBoolean(searchObject, filter, "work_placement");
	filter = handleBoolean(searchObject, filter, "any_background");
	filter = handleBoolean(searchObject, filter, "sort_desc");

	filter = handleDimension(urlSearchParams, filter, "qs_ranking");

	filter = handleDimension(urlSearchParams, filter, "toefl_overall");
	filter = handleDimension(urlSearchParams, filter, "toefl_listening");
	filter = handleDimension(urlSearchParams, filter, "toefl_speaking");
	filter = handleDimension(urlSearchParams, filter, "toefl_reading");
	filter = handleDimension(urlSearchParams, filter, "toefl_writing");

	filter = handleDimension(urlSearchParams, filter, "ielts_overall");
	filter = handleDimension(urlSearchParams, filter, "ielts_listening");
	filter = handleDimension(urlSearchParams, filter, "ielts_speaking");
	filter = handleDimension(urlSearchParams, filter, "ielts_reading");
	filter = handleDimension(urlSearchParams, filter, "ielts_writing");

	return Utils.formalizeFilters(filter);
};

export default useFiltersFromQuery;
