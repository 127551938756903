import _ from "lodash";
import React, { FC } from "react";
import styled from "styled-components";
import { MdDateRange } from "react-icons/md";
import Panel from "./Panel";
import { OpenSeasonType } from "../../types/majorOneType";
import WhiteTag from "./WhiteTag";
import IconText from "./IconText";
import RichText from "../RichText";

type Props = {
	any_background: boolean;
	entry_gpa_country: string;
	entry_gpa_value: string;
	entry_tw_percentile: number;
	entry_tw_gpa: number;
	allow_conditional_offer: boolean;
	open_seasons: OpenSeasonType[];
	apply_deadline: string;
	other_entry_requirement: string;
};

const Container = styled.div`
	& > *:not(:first-child) {
		margin-top: 1rem;
	}
`;

const TagContainer = styled.div`
	display: flex;

	& > *:not(:first-child) {
		margin-left: 22px;
	}
`;

const EntryRequirementsPanel: FC<Props> = ({
	entry_gpa_value,
	entry_tw_gpa,
	entry_tw_percentile,
	any_background,
	allow_conditional_offer,
	apply_deadline,
	open_seasons,
	other_entry_requirement,
}) => {
	if (
		_.isEmpty(entry_gpa_value) &&
		entry_tw_gpa === 0 &&
		entry_tw_percentile === 0 &&
		!any_background &&
		!allow_conditional_offer &&
		open_seasons.length === 0 &&
		_.isEmpty(apply_deadline) &&
		_.isEmpty(other_entry_requirement)
	) {
		return null;
	}

	return (
		<Panel title="Entry Requirements" titleColor="#5384BE">
			<Container>
				<TagContainer>
					{!_.isEmpty(entry_gpa_value) && (
						<WhiteTag>{entry_gpa_value}</WhiteTag>
					)}
					{entry_tw_gpa !== 0 && <WhiteTag>GPA {entry_tw_gpa}</WhiteTag>}
					{entry_tw_percentile !== 0 && (
						<WhiteTag>{entry_tw_percentile}%</WhiteTag>
					)}
					{any_background && <WhiteTag>Relevant background required</WhiteTag>}
					{allow_conditional_offer && (
						<WhiteTag>Conditional admission</WhiteTag>
					)}
				</TagContainer>

				{open_seasons.length !== 0 && (
					<IconText
						icon={
							<MdDateRange style={{ fontSize: 24, color: "var(--purple)" }} />
						}
						text={
							<div>
								Start date{" "}
								{open_seasons
									.map((e) => _.capitalize(e.toLocaleLowerCase()))
									.join(" / ")}
							</div>
						}
					/>
				)}

				{!_.isEmpty(apply_deadline) && (
					<IconText
						icon={<MdDateRange style={{ fontSize: 24, color: "#E58080" }} />}
						text={<div>Application deadline {apply_deadline}</div>}
					/>
				)}

				{!_.isEmpty(other_entry_requirement) && (
					<RichText>{other_entry_requirement}</RichText>
				)}
			</Container>
		</Panel>
	);
};

export default EntryRequirementsPanel;
