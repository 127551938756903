import React, { FC, Fragment } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import styled from "styled-components";
import { CurrencyType, FeeUnitType } from "../../types/majorOneType";
import Panel from "./Panel";
import IconText from "./IconText";
import WhiteTag from "./WhiteTag";

type TuitionFeeType = {
	fee_from: number;
	fee_to: number;
	fee_currency: CurrencyType;
	fee_unit: FeeUnitType;
};

type AppFeeType = {
	app_fee: number;
	app_currency: CurrencyType;
};

type Props = TuitionFeeType & AppFeeType;

const Container = styled.div`
	& > *:not(:first-child) {
		margin-top: 1rem;
	}
`;

const MoneyIcon = styled(AiFillDollarCircle)`
	font-size: 24px;
	color: var(--purple);
`;

const LeftAndRight = styled.div`
	display: flex;
	align-items: center;
`;

const isOnePrice: (fee_from: number, fee_to: number) => boolean = (
	fee_from,
	fee_to
) => {
	return fee_from === fee_to;
};

const showTuitionFee: (obj: TuitionFeeType) => boolean = ({
	fee_from,
	fee_to,
	fee_currency,
	fee_unit,
}) => {
	return (
		fee_from !== 0 &&
		fee_to !== 0 &&
		fee_from <= fee_to &&
		fee_currency !== "" &&
		fee_unit !== ""
	);
};

const showApplicationFee: (obj: AppFeeType) => boolean = ({
	app_fee,
	app_currency,
}) => {
	return app_fee !== 0 && app_currency !== "";
};

const toNumberString = (value: number) => {
	return value.toLocaleString();
};

const FeesPanel: FC<Props> = ({
	fee_from,
	fee_to,
	fee_currency,
	fee_unit,
	app_fee,
	app_currency,
}) => {
	const isShowTuitionFee = showTuitionFee({
		fee_from,
		fee_to,
		fee_currency,
		fee_unit,
	});
	const isShowApplicationFee = showApplicationFee({ app_fee, app_currency });
	if (!isShowTuitionFee && !isShowApplicationFee) {
		return <Fragment />;
	}

	return (
		<Panel title="Fees" titleColor="#5384BE">
			<Container>
				{isShowTuitionFee && (
					<IconText
						icon={<MoneyIcon />}
						text={
							<div>
								<LeftAndRight>
									<div>Tuition fee</div>
									<div style={{ marginLeft: 20 }}>
										{isOnePrice(fee_from, fee_to)
											? toNumberString(fee_from)
											: `${toNumberString(fee_from)} - ${toNumberString(
													fee_to
											  )}`}
										<span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
										{fee_unit.toLowerCase()}
									</div>
									<div style={{ marginLeft: 30 }}>
										<WhiteTag>{fee_currency}</WhiteTag>
									</div>
								</LeftAndRight>
							</div>
						}
					/>
				)}
				{isShowApplicationFee && (
					<IconText
						icon={<MoneyIcon />}
						text={
							<div>
								<LeftAndRight>
									<div>Application fee</div>
									<div style={{ marginLeft: 20 }}>
										{toNumberString(app_fee)}
									</div>
									<div style={{ marginLeft: 30 }}>
										<WhiteTag>{app_currency}</WhiteTag>
									</div>
								</LeftAndRight>
							</div>
						}
					/>
				)}
			</Container>
		</Panel>
	);
};

export default FeesPanel;
