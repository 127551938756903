import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import FlexBox from "../FlexBox";

type Props = {
	icon: ReactNode;
	text: string;
};

const Container = styled.div`
	display: grid;
	grid-template-columns: 22px 1fr;

	font-size: 12px;
	color: var(--grey);
`;

const Text = styled.div`
	display: flex;
	align-items: center;
	margin-left: 10px;
`;

const IconText: FC<Props> = ({ icon, text }) => {
	return (
		<Container>
			<FlexBox>{icon}</FlexBox>
			<Text>{text}</Text>
		</Container>
	);
};

export default IconText;
