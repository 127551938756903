import React, { FC } from "react";

type Props = {
	children: string;
};

const RichText: FC<Props> = ({ children = "" }) => {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: children.replace(/\r?\n/g, "<br />"),
			}}
		/>
	);
};

export default RichText;
