import React, { FC } from "react";
import { MdStars } from "react-icons/md";
import Panel from "./Panel";
import IconText from "./IconText";
import styled from "styled-components";

type Props = {
	ranking_qs: number;
	ranking_guardian: number;
	ranking_complete_guide: number;
	ranking_us_news: number;
};

const StarIcon = styled(MdStars)`
	color: var(--purple);
	font-size: 20px;
`;

const Container = styled.div`
	> *:not(:first-child) {
		margin-top: 1rem;
	}
`;

const RankingPanel: FC<Props> = ({
	ranking_qs,
	ranking_guardian,
	ranking_complete_guide,
	ranking_us_news,
}) => {
	if (
		!ranking_qs &&
		!ranking_guardian &&
		!ranking_complete_guide &&
		!ranking_us_news
	)
		return null;

	return (
		<Panel title="Ranking" titleColor="#5384BE">
			<Container>
				{ranking_qs !== 0 && (
					<IconText icon={<StarIcon />} text={`QS Ranking ${ranking_qs}`} />
				)}
				{ranking_guardian !== 0 && (
					<IconText icon={<StarIcon />} text={`Guardian ${ranking_guardian}`} />
				)}
				{ranking_complete_guide !== 0 && (
					<IconText
						icon={<StarIcon />}
						text={`Complete Guide ${ranking_complete_guide}`}
					/>
				)}
				{ranking_us_news !== 0 && (
					<IconText
						icon={<StarIcon />}
						text={`US News & World Report ${ranking_us_news}`}
					/>
				)}
			</Container>
		</Panel>
	);
};

export default RankingPanel;
