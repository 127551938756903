import { FilterType } from "../../types/filterType";
import { Dispatch, SetStateAction } from "react";

type SetFilters = Dispatch<SetStateAction<Partial<FilterType>>>;
type OnChangeProps = (
	setFilters: SetFilters,
	field: keyof FilterType,
	value: any
) => void;

const onChange: OnChangeProps = (
	setFilters,
	field: keyof FilterType,
	value: any
) => {
	setFilters((filters) => {
		return {
			...filters,
			[field]: value,
		};
	});
};

export default onChange;
