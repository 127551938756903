import styled from "styled-components";

const QualificationTag = styled.div`
	background-color: #6c87c6;
	color: var(--white);
	border-radius: 6px;
	border: none;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 16px;
`;

export default QualificationTag;
