import _ from "lodash";
import useSearchObject from "./useSearchObject";
import Utils from "../utils";

const defaultPage = 0;

const usePage = () => {
	const searchObj = useSearchObject();
	if (
		_.isEmpty(searchObj) ||
		!_.has(searchObj, "page") ||
		!Utils.isNumericString(searchObj.page)
	) {
		return defaultPage;
	} else {
		return parseInt(searchObj.page);
	}
};

export default usePage;
