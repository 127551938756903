import { FilterType } from "./types/filterType";
import { IMG_PREFIX_URL } from "./config";

class Utils {
	static toBooleanString(value: string): boolean {
		return value === "true" || value === "false";
	}

	static toBooleanTrueString(value: string): boolean {
		return value === "true";
	}

	static isNumericString(candidate: string): boolean {
		return candidate.trim() !== "" && Number.isFinite(Number(candidate));
	}

	static formalizeFilters(
		filters: Partial<FilterType> & Partial<{ more: boolean }>
	): Partial<FilterType> {
		for (const [field, value] of Object.entries(filters)) {
			if (typeof value === "string" && value === "") {
				// @ts-ignore
				delete filters[field];
			} else if (typeof value === "boolean") {
				if (!value) {
					// @ts-ignore
					delete filters[field];
				}
			} else if (typeof value === "number") {
				if (value === 0) {
					// @ts-ignore
					delete filters[field];
				}
			} else if (typeof value === "object") {
				if (value.toString() === "0,0") {
					// @ts-ignore
					delete filters[field];
				}
			}
		}
		return filters;
	}

	static getAbsoluteImageUrl(src: string): string {
		return `${IMG_PREFIX_URL}/${src}`;
	}
}

export default Utils;
