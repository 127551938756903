import React, { FC } from "react";
import { IMG_PREFIX_URL } from "../../config";

type Props = {
	variant?: "small" | "big";
	name: string;
	src: string;
};

const UniversityLogo: FC<Props> = ({ variant = "small", name, src }) => {
	return (
		<img
			style={
				variant === "small"
					? { width: 110, height: 50 }
					: { width: 220, height: 100 }
			}
			alt={name}
			src={`${IMG_PREFIX_URL}/${src}`}
		/>
	);
};

export default UniversityLogo;
