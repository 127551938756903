import React, { FC } from "react";
import RowContainer from "../RowContainer";
import RangeBar from "../../RangeBar";
import ClearButton from "../ClearButton";
import DetailButton from "../DetailButton";

type Props = {
	id: string;
	label: string;
	value: [number, number];
	onChange: (value: [number, number]) => void;
	onClear: () => void;
	onDetail: () => void;
	min: number;
	max: number;
	step: number;
};

const ScoreOverallRow: FC<Props> = ({
	id,
	label,
	value,
	onChange,
	min,
	max,
	step,
	onClear,
	onDetail,
}) => {
	return (
		<RowContainer
			field={label}
			right={
				<div style={{ display: "flex" }}>
					<DetailButton style={{ marginRight: "1rem" }} onClick={onDetail} />
					<ClearButton onClick={onClear} />
				</div>
			}
		>
			<div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginRight: 10,
					}}
				>
					Total
				</div>
				<RangeBar
					id={id}
					min={min}
					max={max}
					step={step}
					defaultValue={value}
					onChange={onChange}
				/>
			</div>
		</RowContainer>
	);
};

export default ScoreOverallRow;
