import styled from "styled-components";

const A = styled.a`
	color: #4186d6;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export default A;
