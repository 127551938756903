import _ from "lodash";
import React, { useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { path as majorListPath } from "../../routes/MajorListRoute";
import HomeBg from "./home.jpg";
import Header from "../MainLayout/Header";
import styled from "styled-components";
import SelectRow from "./SelectRow";
import Button from "../Button";

const getQueryString = ({
	country,
	fieldOfStudy,
	qualification,
	more,
}: {
	country: string;
	fieldOfStudy: string;
	qualification: string;
	more: boolean;
}): string => {
	const queryObj: Record<string, string | boolean> = {};

	if (more) {
		queryObj.more = true;
	}

	if (!_.isEmpty(country)) {
		queryObj.country = country;
	}

	if (!_.isEmpty(fieldOfStudy)) {
		queryObj.field_of_study = fieldOfStudy;
	}

	if (!_.isEmpty(qualification)) {
		queryObj.qualification = qualification;
	}

	return queryString.stringify(queryObj);
};

const Container = styled.div`
	background-image: url("${HomeBg}");
	width: 100%;
	min-height: 100vh;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const InnerContainer = styled.div`
	max-width: var(--content-width);
	height: calc(100vh - var(--header-height));

	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ButtonContainer = styled.div`
	margin-top: 5rem;

	display: flex;
	justify-content: center;

	& > :first-child {
		margin-right: 1rem;
	}
`;

function Home() {
	const navigate = useNavigate();
	const [country, setCountry] = useState("");
	const [fieldOfStudy, setFieldOfStudy] = useState("");
	const [qualification, setQualification] = useState("");

	return (
		<Container>
			<Header />
			<InnerContainer>
				<SelectRow
					country={country}
					setCountry={setCountry}
					fieldOfStudy={fieldOfStudy}
					setFieldOfStudy={setFieldOfStudy}
					qualification={qualification}
					setQualification={setQualification}
				/>
				<ButtonContainer>
					<Button
						id="search"
						onClick={async () => {
							await navigate(
								`${majorListPath}?${getQueryString({
									country,
									fieldOfStudy,
									qualification,
									more: false,
								})}`
							);
						}}
					>
						Search
					</Button>
					<Button
						id="more_filters"
						onClick={async () => {
							await navigate(
								`${majorListPath}?${getQueryString({
									country,
									fieldOfStudy,
									qualification,
									more: true,
								})}`
							);
						}}
					>
						More filters
					</Button>
				</ButtonContainer>
			</InnerContainer>
		</Container>
	);
}

export default Home;
