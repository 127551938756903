import React from "react";
import styled from "styled-components";
import SelectField from "../SelectField";
import { tabletQuery } from "../../config";

export type ValueType =
	| ""
	| "qs_ranking_low_to_high"
	| "qs_ranking_high_to_low";
type LabelType = "" | "QS Ranking High to Low" | "QS Ranking Low to High";
type OptionType = {
	id: string;
	disabled: boolean;
	label: LabelType;
	value: ValueType;
};

type Props = {
	value: ValueType;
	onChange: (value: ValueType) => void;
};

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 60px;
	padding-left: 1rem;
	padding-right: 1rem;

	@media screen and ${tabletQuery} {
		justify-content: flex-end;
		padding: 0;
		width: 200px;
	}
`;

const Box = styled.div`
	background-color: white;
	display: flex;
	align-items: center;
	width: 100%;
	height: 60px;
	position: relative;

	::before {
		content: "";
		display: block;
		background-color: var(--grey2);
		width: 100%;
		height: 8px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	::after {
		content: "";
		display: block;
		background-color: var(--grey2);
		width: 100%;
		height: 8px;
		position: absolute;
		bottom: -1px;
		left: 0;
		z-index: 1;
	}
`;

const options: OptionType[] = [
	{
		id: "qs_ranking_high",
		disabled: false,
		label: "QS Ranking High to Low",
		value: "qs_ranking_high_to_low",
	},
	{
		id: "qs_ranking_low",
		disabled: false,
		label: "QS Ranking Low to High",
		value: "qs_ranking_low_to_high",
	},
];

function SortButton({ value, onChange }: Props) {
	return (
		<Container>
			<Box>
				<SelectField
					defaultLabel="Sorting"
					id="sort_button"
					disabled={false}
					options={options}
					error=""
					value={value}
					formStyle={{ width: "100%" }}
					onChange={(value) => onChange(value as ValueType)}
				/>
			</Box>
		</Container>
	);
}

export default SortButton;
