import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useFindOne } from "../../queries/resource.query";
import { MajorOneType } from "../../types/majorOneType";
import TitlePanel from "./TitlePanel";
import FeaturesPanel from "./FeaturePanel";
import LocationPanel from "./LocationPanel";
import RankingPanel from "./RankingPanel";
import FeesPanel from "./FeesPanel";
import EntryRequirementsPanel from "./EntryRequirementsPanel";
import LanguageRequirementPanel from "./LanguageRequirementPanel";

export const prefixPath = "/major";
export const path = `${prefixPath}/:id`;

const Container = styled.div`
	display: flex;
	justify-content: center;
	background-color: var(--grey2);
	min-height: calc(100vh - var(--header-height));
`;

const InnerContainer = styled.div`
	width: var(--content-width);
	overflow: hidden;
	padding-top: 40px;
	padding-bottom: 40px;

	& > *:not(:nth-child(1)) {
		margin-top: 1rem;
	}
`;

function MajorOne() {
	const { id } = useParams();
	const findOne = useFindOne<MajorOneType>("major", id as string);

	if (findOne.isLoading) {
		return <div>Loading...</div>;
	} else if (findOne.isError) {
		return <div>Sorry, we got some error</div>;
	}

	const majorOne = findOne.data!;

	return (
		<Container>
			<InnerContainer>
				<TitlePanel
					logo_cut={majorOne.logo_cut}
					name={majorOne.name}
					website={majorOne.website}
					university={majorOne.university}
					qualification={majorOne.qualification}
					type_of_school={majorOne.type_of_school}
					level={majorOne.level}
				/>
				{!_.isEmpty(majorOne.features) && (
					<FeaturesPanel features={majorOne.features} />
				)}
				<LocationPanel
					country={majorOne.country}
					city={majorOne.city}
					address={majorOne.address}
					pace={majorOne.pace}
					duration={majorOne.duration}
					work_placement={majorOne.work_placement}
					has_post_study_work_visa={majorOne.has_post_study_work_visa}
				/>
				<RankingPanel
					ranking_qs={majorOne.ranking_qs}
					ranking_guardian={majorOne.ranking_guardian}
					ranking_complete_guide={majorOne.ranking_complete_guide}
					ranking_us_news={majorOne.ranking_us_news}
				/>
				<FeesPanel
					fee_from={majorOne.fee_from}
					fee_to={majorOne.fee_to}
					fee_currency={majorOne.fee_currency}
					fee_unit={majorOne.fee_unit}
					app_fee={majorOne.app_fee}
					app_currency={majorOne.app_currency}
				/>
				<LanguageRequirementPanel
					ielts_overall={majorOne.ielts_overall}
					ielts_listening={majorOne.ielts_listening}
					ielts_speaking={majorOne.ielts_speaking}
					ielts_reading={majorOne.ielts_reading}
					ielts_writing={majorOne.ielts_writing}
					ielts_notes={majorOne.ielts_notes}
					toefl_overall={majorOne.toefl_overall}
					toefl_listening={majorOne.toefl_listening}
					toefl_speaking={majorOne.toefl_speaking}
					toefl_reading={majorOne.toefl_reading}
					toefl_writing={majorOne.toefl_writing}
					language_website={majorOne.language_website}
				/>
				<EntryRequirementsPanel
					any_background={majorOne.any_background}
					entry_gpa_country={majorOne.entry_gpa_country}
					entry_gpa_value={majorOne.entry_gpa_value}
					entry_tw_percentile={majorOne.entry_tw_percentile}
					allow_conditional_offer={majorOne.allow_conditional_offer}
					entry_tw_gpa={majorOne.entry_tw_gpa}
					open_seasons={majorOne.open_seasons}
					apply_deadline={majorOne.apply_deadline}
					other_entry_requirement={majorOne.other_entry_requirement}
				/>
			</InnerContainer>
		</Container>
	);
}

export default MajorOne;
