import { RouteObject } from "react-router-dom";
import HomeRoute, { path as HomePath } from "./HomeRoute";
import MajorListRoute, { path as MajorListPath } from "./MajorListRoute";
import MajorOneRoute, { path as MajorOnePath } from "./MajorOneRoute";

const routes: RouteObject[] = [
	{
		id: "HomeRoute",
		path: HomePath,
		element: <HomeRoute />,
	},
	{
		id: "MajorListRoute",
		path: MajorListPath,
		element: <MajorListRoute />,
	},
	{
		id: "MajorOneRoute",
		path: MajorOnePath,
		element: <MajorOneRoute />,
	},
];

export default routes;
