import React, { FC, ReactNode, useState } from "react";
import ScoreOverallRow from "./ScoreOverallRow";
import { DefaultValueType, OnChangeType } from "./types";
import RangeBar from "../../RangeBar";
import styled from "styled-components";
import { tabletQuery } from "../../../config";

type ScoreFieldsProps = {
	id: string;
	label: string;
	step: number;
	totalMin: number;
	totalMax: number;
	eachMin: number;
	eachMax: number;
	defaultValue: DefaultValueType;
	onChange: OnChangeType;
};

function ScoreBox({ topic, children }: { topic: string; children: ReactNode }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ marginRight: "10px" }}>{topic}</div>
			{children}
		</div>
	);
}

const SkillContainer = styled.div`
	@media screen and ${tabletQuery} {
		display: grid;
		grid-template-areas:
			"listening speaking"
			"reading   writing";

		> :nth-child(2) {
			grid-area: listening;
		}
		> :nth-child(3) {
			grid-area: speaking;
		}
		> :nth-child(4) {
			grid-area: reading;
		}
		> :nth-child(5) {
			grid-area: writing;
		}

		> :nth-child(1),
		> :nth-child(2),
		> :nth-child(3),
		> :nth-child(4),
		> :nth-child(5) {
			margin-bottom: 0.5rem;
			margin-left: 90px;
			margin-top: 1rem;
		}
	}
`;

const ScoreFields: FC<ScoreFieldsProps> = ({
	id,
	label,
	step,
	totalMin,
	totalMax,
	eachMin,
	eachMax,
	defaultValue,
	onChange: propsOnChange,
}) => {
	const [open, setOpen] = useState(false);
	const [state, setState] = useState(defaultValue);
	const onChange = (field: keyof DefaultValueType, value: [number, number]) => {
		const toMap = {
			...state,
			[field]: value,
		};

		propsOnChange(toMap);
		setState((ignored) => toMap);
	};

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "flex-start",
				flexDirection: "column",
			}}
		>
			<ScoreOverallRow
				id={`${id}_overall`}
				label={label}
				min={totalMin}
				max={totalMax}
				step={step}
				value={state.overall}
				onChange={(value) => {
					onChange("overall", value);
				}}
				onDetail={() => setOpen((open) => !open)}
				onClear={() => {
					onChange("overall", [0, 0]);
					onChange("listening", [0, 0]);
					onChange("speaking", [0, 0]);
					onChange("reading", [0, 0]);
					onChange("writing", [0, 0]);

					propsOnChange({
						...state,
						overall: [0, 0],
						listening: [0, 0],
						speaking: [0, 0],
						reading: [0, 0],
						writing: [0, 0],
					});
					setState((ignored) => {
						return {
							overall: [0, 0],
							listening: [0, 0],
							speaking: [0, 0],
							reading: [0, 0],
							writing: [0, 0],
						};
					});
				}}
			/>
			{open && (
				<SkillContainer>
					<div style={{ width: "130px" }}></div>
					<ScoreBox topic="Listening">
						<RangeBar
							id={id}
							min={eachMin}
							max={eachMax}
							step={step}
							defaultValue={state.listening}
							onChange={(value) => {
								onChange("listening", value);
							}}
						/>
					</ScoreBox>
					<ScoreBox topic="Speaking">
						<RangeBar
							id={id}
							min={eachMin}
							max={eachMax}
							step={step}
							defaultValue={state.speaking}
							onChange={(value) => {
								onChange("speaking", value);
							}}
						/>
					</ScoreBox>
					<ScoreBox topic="Reading">
						<RangeBar
							id={id}
							min={eachMin}
							max={eachMax}
							step={step}
							defaultValue={state.reading}
							onChange={(value) => {
								onChange("reading", value);
							}}
						/>
					</ScoreBox>
					<ScoreBox topic="Writing">
						<RangeBar
							id={id}
							min={eachMin}
							max={eachMax}
							step={step}
							defaultValue={state.writing}
							onChange={(value) => {
								onChange("writing", value);
							}}
						/>
					</ScoreBox>
				</SkillContainer>
			)}
		</div>
	);
};

export default ScoreFields;
