import React, { FC } from "react";
import styled from "styled-components";
import UniversityLogo from "../UniversityLogo";
import FlexBox from "../FlexBox";

type Props = {
	name: string;
	logo_cut: string;
	qualification: string;
};

const Container = styled.div`
	min-height: 45px;

	display: grid;
	grid-template-columns: 110px 1fr;
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div`
	padding-left: 5px;
	padding-right: 5px;
	word-break: break-word;
	color: var(--grey);
	font-size: 14px;
	font-weight: bold;
`;

const QualificationText = styled(FlexBox)`
	border: solid 1px var(--grey);
	border-radius: 6px;
	color: var(--grey);
	width: fit-content;
	padding: 2px 20px 2px 20px;
	margin-top: 14px;
	margin-left: 9px;
`;

const TopContainer: FC<Props> = ({ logo_cut, name, qualification }) => {
	return (
		<Container>
			<LeftContainer>
				<UniversityLogo name={name} src={logo_cut} />
				<QualificationText>{qualification}</QualificationText>
			</LeftContainer>
			<RightContainer>{name}</RightContainer>
		</Container>
	);
};

export default TopContainer;
